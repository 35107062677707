import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"
import Layout from "@components/Layout"
import ImageButton from "@components/ImageButton"
import wavyFemale from "@assets/wavy_f.png"
import wavyMale from "@assets/wavy_m.png"
import straightFemale from "@assets/straight_f.png"
import straightMale from "@assets/straight_m.png"
import curlyFemale from "@assets/curly_f.png"
import curlyMale from "@assets/curly_m.png"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"

export default function TexturePage() {
  const submit = useSubmit()
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v1.texture.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5 pb-8"
          method="post"
        >
          <div className="grid w-full max-w-md mx-auto grid-cols-2 gap-5">

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? curlyFemale : curlyMale}
              label={t("pages.onboarding.v1.texture.option1")}
              name="texture"
              onChange={handleChange}
              value="1"
            />

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? wavyFemale : wavyMale}
              label={t("pages.onboarding.v1.texture.option2")}
              name="texture"
              onChange={handleChange}
              value="2"
            />

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? straightFemale : straightMale}
              label={t("pages.onboarding.v1.texture.option3")}
              name="texture"
              onChange={handleChange}
              value="3"
            />
          </div>
        </Form>
      </div>
    </Layout>
  )
}
import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import { Navigate, Outlet } from "react-router-dom"
import NewHairstylesPage from "@pages/onboarding/v1/NewHairstylesPage"
import FreshLookPage from "@pages/onboarding/v1/FreshLookPage"
import V1GenderPage from "@pages/onboarding/v1/V1GenderPage"
import EyeCatchPage from "@pages/onboarding/v1/EyeCatchPage"
import TurnHeadsPage from "@pages/onboarding/v1/TurnHeadsPage"
import GlowUpPage from "@pages/onboarding/v1/GlowUpPage"
import NaturalPage from "@pages/onboarding/v1/NaturalPage"
import RealPhotoPage from "@pages/onboarding/v1/RealPhotoPage"
import IdealLengthPage from "@pages/onboarding/v1/IdealLengthPage"
import TexturePage from "@pages/onboarding/v1/TexturePage"
import HairColorPage from "@pages/onboarding/v1/HairColorPage"
import TrendsPage from "@pages/onboarding/v1/TrendsPage"
import OnPointPage from "@pages/onboarding/v1/OnPointPage"
import OneMoreThingPage from "@pages/onboarding/v1/OneMoreThingPage"
import DreamPhotoPage from "@pages/onboarding/v1/DreamPhotoPage"

const v1OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v1",
    children: [
      {
        index: true,
        element: <Navigate to="hairstyles" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <NewHairstylesPage />,
        path: "hairstyles",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FreshLookPage />,
        path: "fresh-look",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <V1GenderPage />,
        path: "select-gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <EyeCatchPage />,
        path: "eye-catch",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TurnHeadsPage />,
        path: "turn-heads",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GlowUpPage />,
        path: "glow-up",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <NaturalPage />,
        path: "natural",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <RealPhotoPage />,
        path: "real-photo",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <DreamPhotoPage />,
        path: "new-hair",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <IdealLengthPage />,
        path: "ideal-length",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TexturePage />,
        path: "texture",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <HairColorPage />,
        path: "hair-color",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TrendsPage />,
        path: "trends",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <OnPointPage />,
        path: "on-point",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <OneMoreThingPage />,
        path: "more",
      },
    ],
  },
]

export default v1OnboardingRoutes

import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import { Navigate, Outlet } from "react-router-dom"
import CuriousPage from "@pages/onboarding/v2/CuriousPage"
import V1GenderPage from "@pages/onboarding/v1/V1GenderPage"
import OneMoreThingPage from "@pages/onboarding/v2/OneMoreThingPage"
import DiscoverPage from "@pages/onboarding/v2/DiscoverPage"
import FutureSelfPage from "@pages/onboarding/v2/FutureSelfPage"
import GrowOldPage from "@pages/onboarding/v2/GrowOldPage"
import FutureLookPage from "@pages/onboarding/v2/FutureLookPage"
import ProudPage from "@pages/onboarding/v2/ProudPage"
import FutureAwaitsPage from "@pages/onboarding/v2/FutureAwaitsPage"

const v2OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v2",
    children: [
      {
        index: true,
        element: <Navigate to="curious" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CuriousPage />,
        path: "curious",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <DiscoverPage />,
        path: "discover",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <V1GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FutureSelfPage />,
        path: "future-self",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GrowOldPage />,
        path: "grow-old",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FutureLookPage />,
        path: "future-look",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ProudPage />,
        path: "proud",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <FutureAwaitsPage />,
        path: "future-awaits",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <OneMoreThingPage />,
        path: "more",
      },
    ],
  },
]

export default v2OnboardingRoutes

import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction"
import { Navigate, Outlet } from "react-router-dom"
import GenderPage from "@pages/onboarding/GenderPage"
import TryFirstPage from "@pages/onboarding/TryFirstPage"
import BestLookPage from "@pages/onboarding/BestLookPage"
import CurrentStylePage from "@pages/onboarding/CurrentStylePage"
import ComfortPage from "@pages/onboarding/ComfortPage"
import PersonalityPage from "@pages/onboarding/PersonalityPage"
import EyeCatchPage from "@pages/onboarding/v1/EyeCatchPage"
import TurnHeadsPage from "@pages/onboarding/v1/TurnHeadsPage"
import DreamPhotoPage from "@pages/onboarding/v1/DreamPhotoPage"
import IdealLengthPage from "@pages/onboarding/v1/IdealLengthPage"
import GlowUpPage from "@pages/onboarding/v1/GlowUpPage"
import CuriousGenderPage from "@pages/onboarding/v3/CuriousGenderPage"
import TrendsPage from "@pages/onboarding/v1/TrendsPage"
import OnPointPage from "@pages/onboarding/v1/OnPointPage"
import CompanyPage from "@pages/onboarding/CompanyPage"

const v3OnboardingRoutes = [
  {
    element: <Outlet />,
    path: "/onboarding/v3",
    children: [
      {
        index: true,
        element: <Navigate to="gender" replace />,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GenderPage />,
        path: "gender",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TryFirstPage />,
        path: "try-first",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <BestLookPage />,
        path: "best-look",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ComfortPage />,
        path: "comfort",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PersonalityPage />,
        path: "personality",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <EyeCatchPage />,
        path: "eye-catch",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TurnHeadsPage />,
        path: "turn-heads",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CurrentStylePage />,
        path: "style",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <DreamPhotoPage />,
        path: "dream-photo",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <IdealLengthPage />,
        path: "ideal-length",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <GlowUpPage />,
        path: "glow-up",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CuriousGenderPage />,
        path: "curious",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <TrendsPage />,
        path: "trends",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <OnPointPage />,
        path: "on-point",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
    ],
  },
]

export default v3OnboardingRoutes

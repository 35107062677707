export default function ImageButton({
  className,
  name,
  image,
  label,
  onChange,
  value,
}: {
  className?: string;
  image?: string;
  label?: string;
  name: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) {

  return (
    <label className={`${className} theme-button-input-image flex flex-col items-center gap-2`}>
      <input
        className="hidden"
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />

      {image && <img className={"w-full"} src={image}/>}

      {label}
    </label>
  )
}

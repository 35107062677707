import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"

export default function IdealLengthPage() {
  const submit = useSubmit()
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="mt-11 shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v1.idealLength.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"
            label={isFemale ? t("pages.onboarding.v1.idealLength.option1") : t("pages.onboarding.v1.idealLength.option1_male")}
            name="hairLength"
            onChange={handleChange}
            value="short"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={isFemale ? t("pages.onboarding.v1.idealLength.option2") : t("pages.onboarding.v1.idealLength.option2_male")}
            name="hairLength"
            onChange={handleChange}
            value="medium"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={isFemale ? t("pages.onboarding.v1.idealLength.option3") : t("pages.onboarding.v1.idealLength.option3_male")}
            name="hairLength"
            onChange={handleChange}
            value="long"
          />
        </Form>
      </div>
    </Layout>
  )
}
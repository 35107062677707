import { useTranslation } from "react-i18next"
import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import FilterlyLogoSvg from "@components/FilterlyLogoSvg"
import { Form, Link, useSubmit } from "react-router-dom"

export default function NewHairstylesPage() {
  const submit = useSubmit()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="flex shrink-0 items-center justify-between">
          <FilterlyLogoSvg />

          <Link className="text-lg hover:opacity-80 font-openSans" to="/account/sign-in">
            {t("shared.signIn")}
          </Link>
        </div>

        <div className="mt-11 shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v1.newHairstyles.heading")} 🔄
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"
            emoji="⬇️"
            label={t("pages.onboarding.v1.newHairstyles.option1")}
            name="newHairstyles"
            onChange={handleChange}
            value="rarely"
          />

          <RadioInputButton
            className="justify-center py-8"
            emoji="➡️"
            label={t("pages.onboarding.v1.newHairstyles.option2")}
            name="newHairstyles"
            onChange={handleChange}
            value="occasionally"
          />

          <RadioInputButton
            className="justify-center py-8"
            emoji="⬆️"
            label={t("pages.onboarding.v1.newHairstyles.option3")}
            name="newHairstyles"
            onChange={handleChange}
            value="frequently"
          />
        </Form>
      </div>
    </Layout>
  )
}
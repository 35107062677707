import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"

export default function TrendsPage() {
  const submit = useSubmit()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="mt-11 shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v1.trends.heading")}
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.v1.trends.option1")}
            name="trends"
            onChange={handleChange}
            value="professional"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.v1.trends.option2")}
            name="trends"
            onChange={handleChange}
            value="creative"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.v1.trends.option3")}
            name="trends"
            onChange={handleChange}
            value="artistic"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.v1.trends.option4")}
            name="trends"
            onChange={handleChange}
            value="beauty"
          />
        </Form>
      </div>
    </Layout>
  )
}
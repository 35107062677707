import BulletPoint from "@components/BulletPoint"
import { useTranslation } from "react-i18next"

export default function Features() {
  const { t } = useTranslation()

  return (
    <div className="space-y-11 font-openSans">
      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints1.bestLookText")}
        title={t("pages.checkout.plans.bulletPoints1.bestLookTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints1.aiPhotoText")}
        title={t("pages.checkout.plans.bulletPoints1.aiPhotoTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints1.transformYourPhotosText")}
        title={t("pages.checkout.plans.bulletPoints1.transformYourPhotosTitle")}
      />

      <BulletPoint
        text={t("pages.checkout.plans.bulletPoints1.perfectPhotosText")}
        title={t("pages.checkout.plans.bulletPoints1.perfectPhotosTitle")}
      />
    </div>
  )
}

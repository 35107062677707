import { redirect } from "react-router-dom"

const ORDERED_DEFAULT_ONBOARDING_ROUTES = [
  "gender",
  "tryFirst",
  "bestLook",
  "style",
  "sharePhoto",
  "comfort",
  "personality",
  "company",
]

const V1_ONBOARDING_ROUTES = [
  "hairstyles",
  "fresh-look",
  "select-gender",
  "eye-catch",
  "turn-heads",
  "glow-up",
  "natural",
  "real-photo",
  "new-hair",
  "ideal-length",
  "texture",
  "hair-color",
  "trends",
  "on-point",
  "more",
]

const V2_ONBOARDING_ROUTES = [
  "curious",
  "discover",
  "gender",
  "future-self",
  "grow-old",
  "future-look",
  "proud",
  "future-awaits",
  "more",
]

const V3_ONBOARDING_ROUTES = [
  "gender",
  "try-first",
  "best-look",
  "comfort",
  "personality",
  "eye-catch",
  "turn-heads",
  "style",
  "dream-photo",
  "ideal-length",
  "glow-up",
  "curious",
  "trends",
  "on-point",
  "company",
]

export default async function navigateToNextOnboardingPageAction({
  request,
}: {
  request: Request;
}) {
  const formData = await request.formData()

  // NOTE: Get form data entries:
  // console.log("formData", Object.fromEntries(formData));

  // TODO: Multiple checkbox values are not being captured correctly
  // const topics = formData.getAll("topics");
  // console.log("topics", topics);

  const next = getNext(request.url)

  const currentPage = request.url.split("/").pop()?.split("?")[0]

  if (!next) {
    if (currentPage === "personalize-experience") {
      const personalizeExperience = formData.get(
        "personalize-experience",
      ) as string

      if (personalizeExperience === "yes") {
        return redirect("/onboarding/company")
      } else {
        return redirect("/onboarding/short/company")
      }
    } else {
      return redirect("/sign-up/password")
    }
  }

  const nextPathname = getNextPathName({ next, url: request.url })

  if (currentPage === "success") {
    const hash = formData.get("success") as string

    return redirect(`${nextPathname}#${hash}`)
  } else {
    return redirect(nextPathname)
  }
}

function getNext(url: string): string | undefined {
  const pathname = new URL(url).pathname
  const current = pathname.split("/").pop()

  if (!current) return

  const orderedOnboardingRoutes = getOrderedOnboardingRoutes(pathname)
  const currentIndex = orderedOnboardingRoutes.indexOf(current)
  const next = orderedOnboardingRoutes[currentIndex + 1]

  return next
}

function getNextPathName({ next, url }: { next: string; url: string }): string {
  const currentPathname = new URL(url).pathname
  const paths = currentPathname.split("/")
  const basePathname = paths.slice(0, -1).join("/")
  const nextPathname = basePathname + "/" + next
  return nextPathname
}

function getOrderedOnboardingRoutes(pathname: string): string[] {
  if (pathname.includes("v1")) {
    return V1_ONBOARDING_ROUTES
  } else if (pathname.includes("v2")) {
    return V2_ONBOARDING_ROUTES
  } else if (pathname.includes("v3")) {
    return V3_ONBOARDING_ROUTES
  }

  return ORDERED_DEFAULT_ONBOARDING_ROUTES
}

import Features from "@components/Features"
import FrequentlyAskedQuestions from "@components/FrequentlyAskedQuestions"
import LimitedTimeOfferDialog from "@components/LimitedTimeOfferDialog"
import Plans from "@components/Plans"
import SubscriptionForm from "@components/SubscriptionForm"
import ErrorPage from "@pages/ErrorPage"
import LoadingPage from "@pages/LoadingPage"
import { Suspense, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Await, useLoaderData } from "react-router-dom"
import {
  GetPricesData,
  GetPricesLoaderResult,
} from "src/loaders/getPricesLoader"

export default function PlansPage() {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const data = useLoaderData() as GetPricesLoaderResult
  const { t } = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsDialogOpen(true)
    }, 30000)

    return () => clearTimeout(timeout)
  }, [])

  if (isSubmitting) return <LoadingPage />

  return (
    <Suspense fallback={<LoadingPage />}>
      <Await errorElement={<ErrorPage />} resolve={data.getPricesData}>
        {(getPricesData: GetPricesData) => (
          <>
            <div className="custom-container pb-14 pt-8">

              <h1 className="break-words px-8 text-center font-openSansBold text-2xl mb-14">
                {t("pages.checkout.plans.heading1")}!
              </h1>

              <section className="mb-16">
                <Plans
                  currency={getPricesData.currency}
                  introPriceCoupon={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon
                      : undefined
                  }
                  isSubmitting={isSubmitting}
                  setIsSubmitting={setIsSubmitting}
                  weeklyPrice={getPricesData.weeklyPrice}
                  yearlyPrice={getPricesData.yearlyPrice}
                />
              </section>

              <section className="mb-12">
                <Features />
              </section>

              <section className="mb-24">
                <FrequentlyAskedQuestions />
              </section>

              <section className="space-y-4 text-center">
                <SubscriptionForm
                  buttonClassName="uppercase"
                  buttonTitle={t("pages.checkout.plans.subscribeNow")}
                  couponId={
                    getPricesData.subscriptionStatus === "none"
                      ? getPricesData.introPriceCoupon?.id
                      : undefined
                  }
                  currency={getPricesData.currency}
                  isSubmitting={isSubmitting}
                  plan="yearly"
                  priceId={getPricesData.yearlyPrice?.id}
                  setIsSubmitting={setIsSubmitting}
                />

                <p className="font-openSansSemiBold">
                  {t("pages.checkout.plans.cancelAnytime")}
                </p>
              </section>
            </div>

            <LimitedTimeOfferDialog
              currency={getPricesData.currency}
              isSubmitting={isSubmitting}
              onClose={() => setIsDialogOpen(false)}
              open={isDialogOpen}
              periodAmount={12}
              periodUnit="month"
              setIsSubmitting={setIsSubmitting}
              stripeIntroPriceCoupon={
                getPricesData.subscriptionStatus === "none"
                  ? getPricesData.introPriceCoupon
                  : undefined
              }
              stripePrice={getPricesData.yearlyPrice}
            />
          </>
        )}
      </Await>
    </Suspense>
  )
}

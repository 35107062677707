import PlanCardNew from "@components/PlanCardNew"
import { StripeCoupon, StripePrice } from "src/types"

interface Props {
  currency: string;
  introPriceCoupon: StripeCoupon | undefined;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  weeklyPrice: StripePrice | undefined;
  yearlyPrice: StripePrice | undefined;
}

export default function Plans({
  currency,
  introPriceCoupon,
  isSubmitting,
  setIsSubmitting,
  weeklyPrice,
  yearlyPrice,
}: Props) {
  return (
    <div className={`grid grid-cols-1 items-end gap-8 md:grid-cols-1 ${weeklyPrice ? "" : ""}`}>
      <PlanCardNew
        bestOffer
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={12}
        periodUnit="month"
        plan="yearly"
        setIsSubmitting={setIsSubmitting}
        stripeIntroPriceCoupon={introPriceCoupon}
        stripePrice={yearlyPrice}
      />

      {/* <PlanCard
        currency={currency}
        isSubmitting={isSubmitting}
        periodAmount={1}
        periodUnit="week"
        plan="weekly"
        setIsSubmitting={setIsSubmitting}
        stripeIntroPriceCoupon={introPriceCoupon}
        stripePrice={weeklyPrice}
      /> */}
    </div>
  )
}

import Layout from "@components/Layout"
import RadioInputButton from "@components/RadioInputButton"
import FilterlyLogoSvg from "@components/FilterlyLogoSvg"
import { useTranslation } from "react-i18next"
import { Form, Link, useSubmit } from "react-router-dom"
import { addGenderToLocalStorage } from "@helpers/localStorage"

export default function GenderPage() {
  const submit = useSubmit()
  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    addGenderToLocalStorage(event.currentTarget.value === "female")
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="flex shrink-0 items-center justify-between">
          <FilterlyLogoSvg />

          <Link className="text-lg hover:opacity-80 font-openSans" to="/account/sign-in">
            {t("shared.signIn")}
          </Link>
        </div>

        <div className="mt-11 shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.gender.heading")}
          </h1>

          <h2 className="text-center text-gray-400 font-openSansSemiBold text-base">
            {t("pages.onboarding.gender.subtitle")}
          </h2>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.gender.female")}
            name="gender"
            onChange={handleChange}
            value="female"
          />

          <RadioInputButton
            className="justify-center py-8"
            label={t("pages.onboarding.gender.male")}
            name="gender"
            onChange={handleChange}
            value="male"
          />
        </Form>
      </div>
    </Layout>
  )
}

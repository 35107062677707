import Button from "@components/Button"
import Layout from "@components/Layout"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"
import {
  Input as HeadlessUiInput,
} from "@headlessui/react"

export default function ProudPage() {
  const [text, setText] = useState("")
  const { t } = useTranslation()

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="grow space-y-6 text-center">
          <h1 className="heading">{t("pages.onboarding.v2.proud.heading")}</h1>
          <h2 className="text-center text-gray-400 font-openSansSemiBold text-base">
            {t("pages.onboarding.v2.proud.subtitle")}
          </h2>
        </div>

        <div className="flex grow flex-col gap-4">
          <HeadlessUiInput
            autoFocus={true}
            className="theme-input-white"
            onChange={(event) => setText(event.target.value)}
            placeholder={t("pages.onboarding.v2.proud.placeholder")}
            type="text"
          />
        </div>

        <Form
          className="flex flex-col justify-between gap-4"
          method="post"
        >
          <Button
            disabled={text.length < 1}
            type="submit"
            title={t("shared.continue")}
          />

          <Button
            className="theme-button-tertiary"
            type="submit"
            title={t("pages.onboarding.v2.proud.skip")}
          />
        </Form>
      </div>
    </Layout>
  )
}

import { useTranslation } from "react-i18next"
import Layout from "@components/Layout"
import ImageButton from "@components/ImageButton"
import FilterlyLogoSvg from "@components/FilterlyLogoSvg"
import { Form, Link, useSubmit } from "react-router-dom"
import pastFemale from "@assets/past_f.png"
import pastMale from "@assets/past_m.png"
import futureFemale from "@assets/future_f.png"
import futureMale from "@assets/future_m.png"
import bothFemale from "@assets/both_f.png"
import bothMale from "@assets/both_m.png"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"

export default function CuriousPage() {
  const submit = useSubmit()
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="flex shrink-0 items-center justify-between">
          <FilterlyLogoSvg />

          <Link className="text-lg hover:opacity-80 font-openSans" to="/account/sign-in">
            {t("shared.signIn")}
          </Link>
        </div>

        <div className="mt-4 shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v2.curious.heading")} 🔄
          </h1>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5"
          method="post"
        >
          <div className="grid w-full max-w-md mx-auto grid-cols-2 gap-5">

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? pastFemale : pastMale}
              label={t("pages.onboarding.v2.curious.option1")}
              name="curious"
              onChange={handleChange}
              value="1"
            />

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? futureFemale : futureMale}
              label={t("pages.onboarding.v2.curious.option2")}
              name="curious"
              onChange={handleChange}
              value="2"
            />

            <ImageButton
              className="justify-center py-8"
              image={isFemale ? bothFemale : bothMale}
              label={t("pages.onboarding.v2.curious.option3")}
              name="curious"
              onChange={handleChange}
              value="3"
            />
          </div>
        </Form>
      </div>
    </Layout>
  )
}
import { useTranslation } from "react-i18next"
import { Form, useSubmit } from "react-router-dom"
import glowUpFemale from "@assets/glow-up-f.png"
import glowUpMale from "@assets/glow-up-m.png"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"
import RadioInputButton from "@components/RadioInputButton"
import Layout from "@components/Layout"

export default function GlowUpPage() {
  const submit = useSubmit()
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form)
  }

  return (
    <Layout>
      <div className="flex h-full flex-col">
        <div className="shrink-0">
          <h1 className="heading mb-4 font-openSansSemiBold">
            {t("pages.onboarding.v1.glowUp.heading")}
          </h1>

          <h2 className="text-center text-gray-400 font-openSansSemiBold text-base">
            {t("pages.onboarding.v1.glowUp.subtitle")}
          </h2>
        </div>

        <Form
          className="flex grow flex-col justify-center space-y-5 pb-8"
          method="post"
        >
          <div className="flex justify-center">
            <img className="w-full max-w-xs py-8 px-8" src={isFemale ? glowUpFemale : glowUpMale} alt="glow up" />
          </div>

          <div className="grid w-full grid-cols-5 gap-5">

            <RadioInputButton
              className="justify-center py-8"
              label="1"
              name="style"
              onChange={handleChange}
              value="1"
            />

            <RadioInputButton
              className="justify-center py-8"
              label="2"
              name="style"
              onChange={handleChange}
              value="2"
            />

            <RadioInputButton
              className="justify-center py-8"
              label="3"
              name="style"
              onChange={handleChange}
              value="3"
            />

            <RadioInputButton
              className="justify-center py-8"
              label="4"
              name="style"
              onChange={handleChange}
              value="4"
            />

            <RadioInputButton
              className="justify-center py-8"
              label="5"
              name="style"
              onChange={handleChange}
              value="5"
            />
          </div>
        </Form>
      </div>
    </Layout>
  )
}
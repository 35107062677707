import Button from "@components/Button"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"
import realPhotoFemale from "@assets/real_photo_f.png"
import realPhotoMale from "@assets/real_photo_m.png"
import { getIsFemaleFromLocalStorage } from "@helpers/localStorage"

export default function RealPhotoPage() {
  const { t } = useTranslation()
  const isFemale = getIsFemaleFromLocalStorage()
  return (
    <main className="custom-container flex h-dvh flex-col justify-between space-y-14 overflow-y-auto pt-8">
      <div className="flex h-full flex-col text-center">
        <div className="flex grow flex-col items-center justify-top">

          <h1 className="heading my-3">
            {t("pages.onboarding.v1.realPhoto.heading")}
          </h1>

          <h2 className="text-center text-gray-400 font-openSansSemiBold text-base px-4">
            {t("pages.onboarding.v1.realPhoto.subtitle")}
          </h2>

          <img className="w-full max-w-xs py-16 px-8" src={isFemale ? realPhotoFemale : realPhotoMale} alt="real photo" />

        </div>

        <Form className="py-8" method="post">
          <Button title={t("shared.continue")} type="submit" />
        </Form>
      </div>
    </main>
  )
}